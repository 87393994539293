import React, { useEffect, useMemo, useState } from 'react';

import NavigationMobile from 'components/NavigationMobile/NavigationMobile';
import useFeatureChecker from 'services/common/useFeatureChecker/useFeatureChecker';

import ExercisesMobileViewGrid from 'components/ExercisesMobileViewGrid/ExercisesMobileViewGrid';
import useLayoutStore from 'storages/layout';
import ExerciseHeaderMobile from './_components/ExerciseHeaderMobile/ExerciseHeaderMobile';
import ExerciseMain from './_components/ExerciseMain/ExerciseMain';
import FEATURES from '../../constants/dictionaries/features';
import ExercisesFilters from '../../uniqueComponents/Exercises/ExercisesFilters/ExercisesFilters';
import SheetsFilters from '../../uniqueComponents/Sheets/SheetsFilters/SheetsFilters';
import useSubjectStore from '../../storages/subject';
import useNavigation from '../../services/exercises/useNavigation/useNavigation';

const getFilters = feature => {
  switch (feature) {
    case FEATURES.EXERCISES:
      return <ExercisesFilters />;
    case FEATURES.SHEETS:
      return <SheetsFilters />;
    default:
      return null;
  }
};

function ExerciseMobileView() {
  const { useDataStore, isChosenExercise, feature, isExercise, isSheet } = useFeatureChecker();

  const { currentExercise, fetched, exerciseCursor, fileType, exercisesCount, month, subjectExercisesCount, mark } = useDataStore();
  const { currentName, buttonsState, fileTypeChange, previous, nextWithMarking } = useNavigation();

  const { subject } = useSubjectStore();
  const [zoomed, setZoomed] = useState(false);

  const { openMobileFilterDrawer, closeMobileFilterDrawer } = useLayoutStore();

  const drawerCaption = useMemo(() => {
    if (isExercise) return exercisesCount || subjectExercisesCount || '';
    if (isSheet) return 'wybierz arkusz';
    return '';
  }, [isExercise, isSheet, subjectExercisesCount, exercisesCount]);

  useEffect(() => {
    if (month) closeMobileFilterDrawer();
  }, [month]);

  useEffect(() => {
    if (!fetched) openMobileFilterDrawer();
    if (isChosenExercise && exercisesCount === 0) openMobileFilterDrawer();
  }, [isChosenExercise, exercisesCount, fetched]);

  useEffect(() => {
    setZoomed(false);
  }, [exerciseCursor]);
  return (
    <ExercisesMobileViewGrid
      drawerCaption={`${drawerCaption}`}
      drawerContent={getFilters(feature)}
      drawerTitle={subject?.name}
      header={
        <ExerciseHeaderMobile
          exerciseName={currentName}
          exerciseNumber={exerciseCursor + 1}
          exercisesCount={exercisesCount}
          isMarked={!!mark}
          showSubjectIcon
        />
      }
      main={<ExerciseMain setZoomed={setZoomed} zoomed={zoomed} />}
      navigation={
        <NavigationMobile
          actions={{
            fileTypeChange,
            onPrevious: previous,
            onNext: nextWithMarking,
          }}
          buttonsEnableState={{
            answer: buttonsState.answer,
            hint: buttonsState.hint,
            next: buttonsState.next,
            previous: buttonsState.previous,
            question: buttonsState.question,
          }}
          fileType={fileType}
          hasAttachments={!!currentExercise?.files?.some(file => file.fileType === 'a')}
          hasExercises={!!currentExercise}
        />
      }
      showSubjectIcon
    />
  );
}

export default ExerciseMobileView;
